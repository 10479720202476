import Alerts from 'shared/components/Alerts';
import i18next from 'i18next';

const haveCommonWords = (string1, string2) => {
  const arr2 = string2.split(' ');
  const checks = string1.split(' ').map((word) => {
    return word.length && arr2.indexOf(word) > -1;
  });
  return checks.indexOf(true) > -1;
};

const errorHandler = (title, errorMessage) => {
  const blackList = 'Undefined Access evaluating function fetch Fetch undefined undefined)';
  if (!haveCommonWords(blackList, errorMessage) && errorMessage !== 'Page Not Found') {
    Alerts.simpleAlert(title, errorMessage);
  }
};

export default { errorHandler };
