import { AnyAction } from 'redux';
import {
  FETCH_MATERIALS_REQUEST,
  FETCH_MATERIALS_ERROR,
  FETCH_MATERIALS_SUCCESS,
} from 'shared/redux/types/MaterialTypes';

export const fetchMaterialsRequest = (): AnyAction => ({
  type: FETCH_MATERIALS_REQUEST,
});

export const fetchMaterialsSuccess = ( payload ): AnyAction => ({
  type: FETCH_MATERIALS_SUCCESS,
  payload,
});

export const fetchMaterialsError =  (payload: any): AnyAction => ({
  type: FETCH_MATERIALS_ERROR,
  payload
});
