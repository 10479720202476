import './App.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  createErrorMessageSelector,
  createLoadingSelector,
} from 'shared/redux/src/GenericSeletors';
import { getAuthState, getErrorState, getTranslationsState } from 'shared/redux/src/StatesGetter';
import CustomErrorUtils from 'shared/modules/CustomErrorUtils';
import NavigationService from 'shared/services/NavigationService';
import { loginCheckPoint } from 'shared/modules/AuthUtils';
import { Routes } from 'shared/components/Routes';
import { getEnv, isDoctor } from 'shared/services/EnvService';
import StorageService from 'shared/services/StorageService';
import LocalEventsService from 'shared/services/LocalEventsService';
import { getAppointmentSettingsRequest } from 'shared/redux/actions/AppointmentActions';
import i18next from 'i18next';
import ReactGA from 'react-ga';
import _ from 'lodash';
import i18n from './shared/i18n/I18n';
import {io} from "socket.io-client";
import socketService from "./shared/services/SocketService";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector(getAuthState, shallowEqual);
  const translations = useSelector(getTranslationsState, shallowEqual);
  const loadingSelector = createLoadingSelector();
  const isLoading = useSelector(loadingSelector);
  const errorState = useSelector(getErrorState, shallowEqual);
  const SOCKET_SERVER_URL =  `${getEnv('BASE_URL')}`;


  useEffect(() => {
    const error = createErrorMessageSelector(errorState);
    if (error?.message) {
      CustomErrorUtils.errorHandler(i18next.t('error'), error.message);
    }
  }, [errorState]);


  useEffect(() => {
    socketService.connect().then(() => {
      console.log('Connected to WebSocket server');
    }).catch((error) => {
      console.log('Connection failed', error);
    });

    return () => {
      socketService.disconnect();
    };
  }, []);



  useEffect(() => {
    const socket = io(SOCKET_SERVER_URL);

    // Return the cleanup function to disconnect the socket
    // when the component unmounts to prevent memory leaks
    // return () => socket.disconnect();
  });


  useEffect(() => {
    if (_.has(translations, 'en')) {
      i18n.addResourceBundle('en', 'translation', translations.en, true, true);
    }
    if (_.has(translations, 'ro')) {
      i18n.addResourceBundle('ro', 'translation', translations.ro, true, true);
    }

    // @ts-ignore
    // i18n.addResource(resourceTranslations);
  }, [translations]);
  useEffect(() => {
    if (!localStorage.getItem('APP_VERSION')) {
      localStorage.setItem('APP_VERSION', `${getEnv('VERSION')}`);
    } else if (
      getEnv('VERSION') &&
      `${getEnv('VERSION')}` !== localStorage.getItem('APP_VERSION')
    ) {
      LocalEventsService.emit('forceLogout');
    }

    dispatch(getAppointmentSettingsRequest({}));

    NavigationService.setNavHistoryRef(navigate);

    (async () => {
      const locale = await StorageService.getData('APP_LANGUAGE', i18next.language);
      await i18next.changeLanguage(locale);
      if (!isLoggedIn) {
        const checkPoint = await loginCheckPoint();
        if (checkPoint.action === 'login') {
          const isLoggedInNow = true;
        }
      }
    })();
  }, []);

  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    // @ts-ignore
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.warn('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    // @ts-ignore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.warn(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      // @ts-ignore
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments);
    };
  }

  return (
      <Routes />
  );
};
export default App;
