import { AnyAction } from 'redux';
import {FETCH_MATERIALS_SUCCESS} from "../types/MaterialTypes";

const MaterialReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_MATERIALS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default MaterialReducer;
