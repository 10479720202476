import { Route, Routes as ReactRouterDomRoutes } from 'react-router-dom';
import MainPage from 'shared/screens/MainPage';

const PublicRoutes = [
  {
    path: '/',
    key: 'root',
    element: <MainPage />,
  },
];

const Routes = () => {
  return (
    <ReactRouterDomRoutes>
      {PublicRoutes.map((route) => (
        <Route key={route.key} path={route.path} element={route.element} />
      ))}
      {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
      {/* <Route path="/404" element={<PageNotFound />} /> */}
    </ReactRouterDomRoutes>
  );
};

export { Routes };
