import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {getDestinationsAPI} from "../api/DestinationsApi";
import {fetchDestinationsError, fetchDestinationsSuccess} from "../actions/DestinationsActions";
import {FETCH_DESTINATIONS_REQUEST} from "../types/DestinationTypes";
import destinationsSelector from "../selector/DestinationSelector";

function* getDestinations(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const destinationsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } =  yield take(destinationsChannel);

    try {
      // @ts-ignore
      const response = yield call(getDestinationsAPI, payload);
      console.log('response', response);
      yield put(fetchDestinationsSuccess(destinationsSelector(response.data.data)));
    } catch ({ message }) {
      yield put(fetchDestinationsError({ message }));
    }
  }
}

function* DestinationsSaga() {
  yield fork(getDestinations, FETCH_DESTINATIONS_REQUEST);
}

export default DestinationsSaga;
