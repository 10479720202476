import { AnyAction } from 'redux';
import {FETCH_DESTINATIONS_SUCCESS} from "../types/DestinationTypes";

const DestinationReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_DESTINATIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default DestinationReducer;
