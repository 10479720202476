import { AnyAction } from 'redux';
import {FETCH_OPTIONS_ERROR, FETCH_OPTIONS_REQUEST, FETCH_OPTIONS_SUCCESS} from "../types/OptionTypes";

export const fetchOptionsRequest = (payload): AnyAction => ({
  type: FETCH_OPTIONS_REQUEST,
  payload,
});

export const fetchOptionsSuccess = ( payload ): AnyAction => ({
  type: FETCH_OPTIONS_SUCCESS,
  payload,
});

export const fetchOptionsError =  (payload: any): AnyAction => ({
  type: FETCH_OPTIONS_ERROR,
  payload
});
