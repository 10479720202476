import React from 'react';
import euFlag from 'shared/assets/images/eu_flag_circle.png';

const LicensePlate = ({licenceNumber} ) => {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '120px',
            backgroundColor: 'white',
            borderRadius: '10px',
            border: '1px solid #000',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
            fontFamily: 'Arial, sans-serif',
            fontSize: '100px',
            fontWeight: 'bold',
            marginBottom: '10px'
        }}>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                width: '120px',
                height: '120px',
                backgroundColor: '#004AAD',
                marginRight: '15px',
                borderBottomLeftRadius: 8,
                borderTopLeftRadius: 8,
                marginLeft: 0
            }}>
            <img src={euFlag} style={{ width: '60px', height: '60px'}} />
            <div style={{ color: 'white', fontWeight: 'normal', fontSize: '40px' }}>RO</div>
            </div>
            {licenceNumber}
        </div>
    );
};

export default LicensePlate;
