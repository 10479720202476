import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import countriesSelector from 'shared/redux/selector/CountrySelector';
import {getMaterialsAPI} from "../api/MaterialApi";
import {fetchMaterialsError, fetchMaterialsSuccess} from "../actions/MaterialsActions";
import {FETCH_MATERIALS_REQUEST} from "../types/MaterialTypes";
import materialsSelector from "../selector/MaterialSelector";

function* getMaterials(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const materialsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(materialsChannel);
    try {
      // @ts-ignore
      const response = yield call(getMaterialsAPI);
      yield put(fetchMaterialsSuccess(materialsSelector(response.data.data)));
    } catch ({ message }) {
      yield put(fetchMaterialsError({ message }));
    }
  }
}

function* MaterialsSaga() {
  yield fork(getMaterials, FETCH_MATERIALS_REQUEST);
}

export default MaterialsSaga;
