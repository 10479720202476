import { AnyAction } from 'redux';
import {FETCH_ORDER_SUCCESS} from "../types/OrderTypes";

const OrderReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_ORDER_SUCCESS:
      return action.payload;
    case 'CREATE_ORDER_REQUEST':
      return { ...state, loading: true };
    case 'CREATE_ORDER_SUCCESS':
      return { ...state, loading: false, success: true };
    case 'CREATE_ORDER_FAILURE':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default OrderReducer;
