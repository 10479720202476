import i18next from 'i18next';

const createOrderSelector = (order) => {
    return {
      vehicle: order?.vehicle,
      quantity: order.quantity
    };

};

export default createOrderSelector;
