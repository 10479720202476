import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {creatOrderAPI, getOrderAPI} from "../api/OrderApi";
import {fetchMaterialsError, fetchMaterialsSuccess} from "../actions/MaterialsActions";
import materialsSelector from "../selector/MaterialSelector";
import {CREATE_ORDER_REQUEST, FETCH_ORDER_REQUEST} from "../types/OrderTypes";
import {createOrderError, createOrderSuccess} from "../actions/OrderActions";
import createOrderSelector from "../selector/OrderSelector";
import Alerts from "../../components/Alerts";
import {useNavigate} from "react-router-dom";
import navigationService from "../../services/NavigationService";

function* getOrder(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const orderChannel = yield actionChannel(actionType);
  while (true) {
    yield take(orderChannel);
    try {
      // @ts-ignore
      const response = yield call(getOrderAPI);
      yield put(fetchMaterialsSuccess(materialsSelector(response.data.data)));
    } catch ({ message }) {
      yield put(fetchMaterialsError({ message }));
    }
  }
}
function* createOrder(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const createOrderChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } =  yield take(createOrderChannel);
    try {
      console.log('payload', payload);
      // @ts-ignore
      const response = yield call(creatOrderAPI, payload);
      yield put(createOrderSuccess(createOrderSelector(response.data.data)));
      Alerts.simpleAlert('Info', 'Comanda a fost plasata cu success! Va rugam sa deplasati autoutilitara in zona de incarcare!');
    } catch ({ message }) {
      yield put(createOrderError({ message }));
    }
  }
}

function* OrderSaga() {
  yield fork(createOrder, CREATE_ORDER_REQUEST);
  yield fork(getOrder, FETCH_ORDER_REQUEST);
}

export default OrderSaga;
