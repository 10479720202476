import { AnyAction } from 'redux';
import {FETCH_OPTIONS_SUCCESS} from "../types/OptionTypes";

const OptionReducer = (state = [], action: AnyAction) => {
  switch (action.type) {
    case FETCH_OPTIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default OptionReducer;
