import 'shared/styles/gate.css';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {getDestinationsState, getMaterialsState, getOptionsState, getOrderState} from "../redux/src/StatesGetter";
import {
  fetchMaterialsRequest,
} from 'shared/redux/actions/MaterialsActions';
import {createOrderRequest} from "../redux/actions/OrderActions";
import {io} from "socket.io-client";
import socketService from "../services/SocketService";
import LicensePlate from "../services/LicencePlateService";
import Alerts from "../components/Alerts";
import {Image} from "react-bootstrap";
import  image from '../assets/images/logo_agrewest.png';
import alerts from "../components/Alerts";
import {fetchOptionsRequest} from "../redux/actions/OptionsActions";
import {fetchDestinationsRequest} from "../redux/actions/DestinationsActions";

const MainPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedMaterial, setSelectedMaterial] = useState();
  const [selectedQuantity, setSelectedQuantity] = useState('max');
  const materials = useSelector(getMaterialsState, shallowEqual);
  const options = useSelector(getOptionsState, shallowEqual);
  const destinations = useSelector(getDestinationsState, shallowEqual);
  const [vehicleData, setVehicleData] = useState({ numberPlate: '', _id: ''});
  const [scaleEventData, setScaleEventData] = useState({value: 0, state: ''});
  const [orderEventData, setOrderEventData] = useState({action: ''});
  const [step, setStep] = useState(1);
  const [currentDriver, setCurrentDriver] = useState();
  const [currentDestination, setCurrentDestination] = useState();
  const [currentBuyer, setCurrentBuyer] = useState();
  const [currentTrailer, setCurrentTrailer] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);

  interface EventData {
    action?: string;
    vehicle?: any;
    order?: any;
  }

  const bottomRef =  useRef<HTMLDivElement>(null);
  const [eventData, setEventData] = useState<EventData>({});

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [currentDriver, currentBuyer, currentTrailer, selectedMaterial]);

  useEffect(() => {
    socketService.connect().then(() => {
      console.log('Socket connected!');
      socketService.on('vehicle', (data) => {
        setEventData(data);
        if(data?.vehicle){
          setVehicleData(data?.vehicle);
          dispatch(fetchOptionsRequest({licencePlate: data.vehicle.numberPlate}) );
        }
      });

      socketService.on('scale', (scaleData) => {
        console.log('scaleData', scaleData);
        if(scaleData?.state !== 'idle') {
          setScaleEventData(scaleData);
        }
      });

      socketService.on('order', (orderData) => {
        console.log('orderData', orderData);
          setOrderEventData(orderData);
        { orderData.action == 'completed' && (
            handlePrintDocuments()
        )}
      });

    });
    return () => {
      console.log('Socket disconnected!');
      socketService.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchMaterialsRequest() );
  }, []);

  useEffect(() => {
    if (alertVisible) {
      // Your logic when the alert is visible
    }
  }, [alertVisible]);

  const handleCurrentDriver = async (driver) => {
    setCurrentDriver(driver.id)
  };

  const handleCurrentDestination = async (destination) => {
    setCurrentDestination(destination.lDestinatie)
  };

  const handlePrintDocuments = async () => {

    alerts.textAlert('Succes', 'Incarcarea a fost finalizata cu succes');
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
      window.location.reload();
    }, 5000);

  };
  const handleCurrentBuyer = async (buyer) => {
    setCurrentBuyer(buyer.id)
    dispatch(fetchDestinationsRequest({buyerId: buyer.id}) );
  };
  const handleCurrentTrailer = async (trailer) => {
    setCurrentTrailer(trailer)
  };

  const handleNextStep = async (step, current) => {

    if(!current){
      alerts.errorAlert('Va rugam selectati o optiune');
      return;
    }

    setStep(step)
  };

  const handleOrderClick = async () => {
    if(!selectedMaterial){
      alerts.errorAlert('Va rugam selectati o optiune');
      return;
    }
    setStep(5);

    dispatch(
        createOrderRequest({
          quantity: 0,
          fullLoad: true,
          vehicle: vehicleData._id,
          driverId: currentDriver,
          buyerId: currentBuyer,
          productId: selectedMaterial,
          destinationId: currentDestination,
          trailerLicensePlate: currentTrailer,
        }),
    );
  };

  console.log('vehicleData', vehicleData);
  console.log('Event data:', eventData);
  console.log('orderEventData:', orderEventData);
  console.log('materials:', materials);
  console.log('destinations:', destinations.destinations);
  return (
          <div className="gate-page">
            <main>
              <div className="container-fluid p-0">
                <section>
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                        { !vehicleData.numberPlate && (
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '95vh',
                              fontSize: 90,
                              textAlign: 'center'
                            }}>
                              Numar de inmatriculare neidentificabil
                            </div>

                        )}
                        { vehicleData.numberPlate && (
                            <>
                              <div className="orderForm">
                                <div className="title">Foaie de comandă</div>
                                  <div  style={{display: "flex", justifyContent: 'space-between',  alignItems: 'center', marginRight: 40}}>
                                    <div>
                                      <LicensePlate licenceNumber={vehicleData?.numberPlate} />
                                    </div>
                                    <div>
                                      <div>
                                        <strong>Tara:</strong> {eventData?.order ? eventData?.order?.weightOnArrival/1000 : scaleEventData?.value/1000  } t
                                      </div>
                                    </div>
                                  </div>
                                {eventData?.action === 'final_load' && (
                                    <>
                                      <div>
                                        <strong>Sort comandat:</strong> {eventData?.order?.material || 'Not Assigned'}
                                      </div>
                                      <div className="finalLoad">
                                        <div className="finalLoadItem">
                                          <div className="finalLoadTitle">ÎNCĂRCARE PRIMARĂ</div>
                                          <div className="finalLoadValue">{eventData?.order?.quantity / 1000} t</div>
                                        </div>

                                        <div className="finalLoadItem">
                                          <div className="finalLoadTitle">DE SUPLIMENTAT</div>
                                          <div className="finalLoadValue">{(eventData?.order?.maxWeight - scaleEventData.value) / 1000} t </div>
                                        </div>
                                      </div>
                                        <div className="finalLoadTitle">CANTITATE INCARCATĂ</div>
                                        <div className="finalLoadValue">{scaleEventData?.value / 1000} t</div>
                                    </>
                                )}

                                {eventData?.action === 'initial_load' && step === 1 && (
                                    <>
                                      <div className="title" style={{textAlign: 'center'}}>Sunt soferul </div>
                                          <div className="listDisplay">
                                              {options.drivers.map((driver, index) => (
                                                <>
                                                  <button
                                                      className={`quantityButton ${currentDriver=== driver.id ? 'active' : ''}`}
                                                      onClick={() => {
                                                        handleCurrentDriver(driver);
                                                      }}>
                                                    <div>
                                                      <div>{driver.value}</div>
                                                    </div>
                                                  </button>
                                                </>
                                            ))}
                                          </div>
                                      <div ref={bottomRef}>
                                        <button  className="placeOrderButton"  onClick={() => {
                                          handleNextStep(2, currentDriver);
                                        }}>CONTINUA</button>
                                      </div>
                                    </>
                                )}

                                {eventData?.action === 'initial_load' && step === 2 && (
                                    <>
                                      <div className="title" style={{textAlign: 'center'}}>Numar remorca</div>
                                      <div className="listDisplay">
                                        {options.trailers.map((trailer, index) => (
                                            <>
                                              <button
                                                  className={`quantityButton ${currentTrailer === trailer ? 'active' : ''}`}
                                                  onClick={() => {
                                                    handleCurrentTrailer(trailer);
                                                  }}>
                                                <div>
                                                  <div>{trailer}</div>
                                                </div>
                                              </button>
                                            </>
                                        ))}
                                      </div>
                                      <div ref={bottomRef} className="buttonContainer">
                                        <button className="formButton" onClick={() => setStep(1)}>Inapoi</button>
                                        <button className="formButton" onClick={() => handleNextStep(3, currentTrailer)}>CONTINUA</button>
                                      </div>
                                    </>
                                )}

                                {eventData?.action === 'initial_load' && step === 3 && (
                                    <>
                                      <div className="title" style={{textAlign: 'center'}}>Cumpar pentru</div>
                                      <div className="listDisplay">
                                        {options.buyers.map((buyer, index) => (
                                            <>
                                              <button
                                                  className={`quantityButton ${currentBuyer === buyer.id ? 'active' : ''}`}
                                                  onClick={() => {
                                                    handleCurrentBuyer(buyer);
                                                  }}>
                                                <div>
                                                  <div>{buyer.value}</div>
                                                </div>
                                              </button>
                                            </>
                                        ))}
                                      </div>
                                      <div ref={bottomRef} className="buttonContainer">
                                        <button className="formButton" onClick={() => setStep(2)}>Inapoi</button>
                                        <button className="formButton" onClick={() => handleNextStep(4, currentBuyer)}>CONTINUA</button>
                                      </div>
                                    </>
                                )}

                                {eventData?.action === 'initial_load' && step === 4 && (
                                    <>
                                      <div className="title" style={{textAlign: 'center'}}>Destinatia</div>
                                      <div className="listDisplay">
                                        {destinations.destinations.map((destination, index) => (
                                            <>
                                              <button
                                                  className={`quantityButton ${currentDestination === destination.lDestinatie ? 'active' : ''}`}
                                                  onClick={() => {
                                                    handleCurrentDestination(destination);
                                                  }}>
                                                <div>
                                                  <div>{destination.denumireLocalitateDescarcare}</div>
                                                  {/*<div>{material.size}</div>*/}
                                                </div>
                                              </button>
                                            </>
                                        ))}
                                      </div>
                                      <div ref={bottomRef} className="buttonContainer">
                                        <button className="formButton" onClick={() => setStep(3)}>Inapoi</button>
                                        <button className="formButton" onClick={() => handleNextStep(5, currentBuyer)}>CONTINUA</button>
                                      </div>
                                    </>
                                )}

                                {eventData?.action === 'initial_load' && step === 5 && (
                                    <>
                                      <div className="title" style={{textAlign: 'center'}}>Vreau sa comand</div>
                                      <div className="listDisplay">
                                          {materials.map((material, index) => (
                                              <>
                                                <button
                                                    className={`quantityButton ${selectedMaterial=== material.internalId ? 'active' : ''}`}
                                                    onClick={() => {
                                                      setSelectedMaterial(material.internalId);
                                                    }}>
                                                  <div>
                                                    <div>{material.name}</div>
                                                    <div>{material.size}</div>
                                                  </div>
                                                </button>
                                              </>
                                          ))}
                                      </div>
                                      <div ref={bottomRef} className="buttonContainer">
                                        <button className="formButton" onClick={() => setStep(4)}>Inapoi</button>
                                        <button className="formButton" onClick={() => handleOrderClick()}>PLASARE COMANDĂ</button>
                                      </div>
                                    </>
                                )}
                              </div>
                            </>
                           )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </main>
          </div>
  );
};

export default MainPage;
