import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import { Action } from 'redux';
import {getOptionsAPI} from "../api/OptionsApi";
import {FETCH_OPTIONS_REQUEST} from "../types/OptionTypes";
import {fetchOptionsError, fetchOptionsSuccess} from "../actions/OptionsActions";
import optionsSelector from "../selector/OptionSelector";

function* getOptions(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const optionsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } =  yield take(optionsChannel);

    try {
      // @ts-ignore
      const response = yield call(getOptionsAPI, payload);
      yield put(fetchOptionsSuccess(optionsSelector(response.data.data)));
    } catch ({ message }) {
      yield put(fetchOptionsError({ message }));
    }
  }
}

function* OptionsSaga() {
  yield fork(getOptions, FETCH_OPTIONS_REQUEST);
}

export default OptionsSaga;
