import { AnyAction } from 'redux';
import {
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_SUCCESS,
  FETCH_ORDER_ERROR,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_ERROR,
} from 'shared/redux/types/OrderTypes';

export const fetchOrderRequest = (): AnyAction => ({
  type: FETCH_ORDER_REQUEST,
});

export const fetchOrderSuccess = ( payload ): AnyAction => ({
  type: FETCH_ORDER_SUCCESS,
  payload,
});

export const fetchOrderError =  (payload: any): AnyAction => ({
  type: FETCH_ORDER_ERROR,
  payload
});


export const createOrderRequest = (payload: any): AnyAction => ({
  type: CREATE_ORDER_REQUEST,
  payload
});

export const createOrderSuccess = ( payload ): AnyAction => ({
  type: CREATE_ORDER_SUCCESS,
  payload,
});

export const createOrderError =  (payload: any): AnyAction => ({
  type: CREATE_ORDER_ERROR,
  payload
});
