import { all, fork } from 'redux-saga/effects';
import authSaga from './AuthSaga';
import countriesSaga from './CountrySaga';
import materialsSaga from './MaterialSaga';
import OrderSaga from "./OrderSaga";
import optionsSaga from "./OptionsSaga";
import destinationsSaga from "./DestinationsSaga";


function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(countriesSaga),
    fork(materialsSaga),
    fork(optionsSaga),
    fork(destinationsSaga),
    fork(OrderSaga),
  ]);
}

export default rootSaga;
