export const getAuthState = (state) => state.authState;
export const getSharedState = (state) => state.sharedState;
export const getUserProfileState = (state) => state.userProfileState;
export const getErrorState = (state) => state.errorState;
export const getPatientProfileState = (state) => state.patientProfileState;
export const getTranslationsState = (state) => state.translationsState;
export const getMaterialsState = (state) => state.materialsState;
export const getOptionsState = (state) => state.optionsState;
export const getDestinationsState = (state) => state.destinationsState;
export const getOrderState = (state) => state.orderState;
