/**
 * @ref https://create-react-app.dev/docs/adding-custom-environment-variables/
 */

export const isDoctor = (checkUrlPrefix = false) => {
  if (checkUrlPrefix) {
    const pathParts = window.location.pathname.split('/');
    return pathParts.indexOf('doctor') > -1;
  }

  const doctorTag = document.getElementById('isDoctorApp');
  return !!doctorTag;
};

const getPrefix = (specific) => {
  if (!specific) return 'REACT_APP';
  const extraPrefix = isDoctor() ? 'DOCTOR' : 'PATIENT';
  return `REACT_APP_${extraPrefix}`;
};

export const getEnv = (key: string, specific = false) => {
  const prefix = getPrefix(specific);
  return process.env[`${prefix}_${key}`];
};

export const isRegister = () => {
  const pathParts = window.location.pathname.split('/');
  return pathParts.indexOf('register') > -1;
};

export const isLoginOrRegister = () => {
  const pathParts = window.location.pathname.split('/');
  return (
    pathParts.indexOf('register') > -1 ||
    pathParts.indexOf('login') > -1 ||
    pathParts.indexOf('forgot-password') > -1 ||
    pathParts.indexOf('reset-password') > -1
  );
};

export const isRegisterSteps = () => {
  const pathParts = window.location.pathname.split('/');
  return (
    pathParts.indexOf('register-step-personal-data') > -1 ||
    pathParts.indexOf('register-step-invoice') > -1 ||
    pathParts.indexOf('register-step-specialization') > -1
  );
};

export const activateAccount = () => {
  const pathParts = window.location.pathname.split('/');
  return pathParts.indexOf('activate-account') > -1;
};

export const isLandingPage = () => {
  const pathParts = window.location.pathname.split('/');
  return (
    pathParts.indexOf('landing') > -1 ||
    pathParts.indexOf('contact') > -1 ||
    pathParts.indexOf('about-us') > -1
  );
};

export const mainContentAlignCenter = () => {
  const pathParts = window.location.pathname.split('/');
  return (
    pathParts.indexOf('searching-medic') > -1 ||
    pathParts.indexOf('medic-found') > -1 ||
    pathParts.indexOf('summary-payment-error') > -1 ||
    pathParts.indexOf('confirmed-appointment') > -1 ||
    pathParts.indexOf('waiting-screen') > -1 ||
    pathParts.indexOf('activate-account') > -1
  );
};

export const pageNotFound = () => {
  const pathParts = window.location.pathname.split('/');
  return pathParts.indexOf('404') > -1;
};

export const authFooter = () => {
  const pathParts = window.location.pathname.split('/');
  return pathParts.indexOf('auth') > -1;
};

export const consultationFlowFooter = () => {
  const pathParts = window.location.pathname.split('/');
  return pathParts.indexOf('call-specialist') > -1 || pathParts.indexOf('appointment') > -1;
};

export const isTwilioScreen = () => {
  const pathParts = window.location.pathname.split('/');
  const ref1 = 'videocall';
  const ref2 = 'video-call';
  return (
    pathParts.indexOf(ref1) > -1 ||
    pathParts.indexOf(`${ref1}#`) > -1 ||
    pathParts.indexOf(ref2) > -1 ||
    pathParts.indexOf(`${ref2}#`) > -1
  );
};

export const isPermissionsScreen = () => {
  const pathParts = window.location.pathname.split('/');
  return pathParts.indexOf('permissions') > -1;
};

export const isActivationPage = () => {
  const pathParts = window.location.pathname.split('/');
  return pathParts.indexOf('activate-account') > -1;
};

export const isForgotPasswordPage = () => {
  const pathParts = window.location.pathname.split('/');
  return pathParts.indexOf('reset-password') > -1;
};
