import { AnyAction } from 'redux';
import {FETCH_DESTINATIONS_ERROR, FETCH_DESTINATIONS_REQUEST, FETCH_DESTINATIONS_SUCCESS} from "../types/DestinationTypes";

export const fetchDestinationsRequest = (payload): AnyAction => ({
  type: FETCH_DESTINATIONS_REQUEST,
  payload,
});

export const fetchDestinationsSuccess = ( payload ): AnyAction => ({
  type: FETCH_DESTINATIONS_SUCCESS,
  payload,
});

export const fetchDestinationsError =  (payload: any): AnyAction => ({
  type: FETCH_DESTINATIONS_ERROR,
  payload
});
