import io from 'socket.io-client';
import {getEnv} from "./EnvService";
const SOCKET_SERVER_URL =  `${getEnv('BASE_URL')}`;

class SocketService {
    socket; // Declare the socket variable in the class scope

    constructor() {
        this.socket = null; // Initialize the socket as null
    }

    connect() {
        // This method initializes the socket connection
        this.socket = io(SOCKET_SERVER_URL);
        return new Promise((resolve, reject) => {
            this.socket.on('connect', () => resolve(this.socket)); // Resolve with the socket
            this.socket.on('connect_error', (error) => reject(error));
        });
    }

    disconnect() {
        // Disconnects the socket
        if (this.socket) {
            this.socket.disconnect();
        }
        this.socket = null; // Reset the socket to null to clean up reference
    }

    on(eventName, callback) {
        // Register an event listener to the socket
        if (!this.socket) return;
        this.socket.on(eventName, callback);
    }

    off(eventName, callback) {
        // Remove an event listener from the socket
        if (!this.socket) return;
        this.socket.off(eventName, callback);
    }

    emit(eventName, data) {
        // Emit an event through the socket
        if (!this.socket) return;
        this.socket.emit(eventName, data);
    }
}

// Export an instance of the service
const socketService = new SocketService();
export default socketService;
