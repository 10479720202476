import { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { timeUntil } from 'date-dealer';

const TimerComponent = ({ action, endTime, extraStyle, onTimeAboutToEnd }) => {
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  let intervalRef: NodeJS.Timeout | null = null;
  const clear = () => {
    if (intervalRef) {
      clearInterval(intervalRef);
    }
  };

  const doUpdate = () => {
    if (!endTime?.length) return;
    const { days, hours, minutes: newMinutes, seconds: newSeconds } = timeUntil(endTime);
    if (hours < 0 || days < 0) {
      setMinutes(0);
      setSeconds(0);
      action();
      if (typeof onTimeAboutToEnd === 'function') {
        onTimeAboutToEnd(false);
      }
      clear();
    } else {
      const goodMinutes = hours > 0 ? hours * 60 + newMinutes : newMinutes;
      setMinutes(goodMinutes);
      setSeconds(newSeconds);
    }
    if (hours === 0 && newMinutes <= 1 && typeof onTimeAboutToEnd === 'function') {
      onTimeAboutToEnd(true);
    }
  };
  useEffect(() => {
    doUpdate();
    clear();
    intervalRef = setInterval(() => {
      doUpdate();
    }, 100);
    return () => {
      clear();
    };
  }, []);

  return <p style={extraStyle}>{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</p>;
};

TimerComponent.defaultProps = {
  extraStyle: {},
};

TimerComponent.propTypes = {
  action: PropTypes.func.isRequired,
  onTimeAboutToEnd: PropTypes.func.isRequired,
  endTime: PropTypes.string.isRequired,
  extraStyle: PropTypes.shape({}),
};

export default TimerComponent;
