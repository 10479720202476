import i18next from 'i18next';

const materialsSelector = (materials: any[]) => {
  return materials?.map((row) => {
    return {
      id: row?._id,
      name: row.name,
      size: row.size,
      internalId: row.internalId
    };
  });
};

export default materialsSelector;
