import { Action, combineReducers, Reducer, Store } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PERSIST } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'shared/redux/saga';
import AuthReducer from 'shared/redux/reducers/AuthReducer';
import CountryReducer from 'shared/redux/reducers/CountryReducer';
import { errorReducer, loadingReducer } from 'shared/redux/src/GenericReducer';
import * as localForage from 'localforage';
import { FORCE_LOGOUT_SUCCESS, LOGOUT_SUCCESS } from './types/AuthTypes';
import MaterialReducer from "./reducers/MaterialReducer";
import OrderReducer from "./reducers/OrderReducer";
import OptionReducer from "./reducers/OptionReducer";
import DestinationReducer from "./reducers/DestinationReducer";

const sagaMiddleware = createSagaMiddleware();

const persistRootKey = `agrewest_web`;

const appReducer = combineReducers({
  loadingState: loadingReducer,
  errorState: errorReducer,
  authState: AuthReducer as Reducer<void, Action>,
  countriesState: CountryReducer as Reducer<void, Action>,
  materialsState: MaterialReducer as Reducer<void, Action>,
  optionsState: OptionReducer as Reducer<void, Action>,
  destinationsState: DestinationReducer as Reducer<void, Action>,
  orderState: OrderReducer as Reducer<void, Action>,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS || action.type === FORCE_LOGOUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: persistRootKey,
  storage: localForage,
  version: 1,
  blacklist: ['loadingState', 'errorState'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store: Store = configureStore({
  reducer: persistedReducer,
  devTools: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { persistor, store };
